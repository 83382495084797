export default {
  id: {
    type: Number,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  email: {
    label: 'Correo electronico',
    type: String
  },
  password: {
    label: 'Contraseña',
    type: String,
    visibility: (item) => item.id === undefined,
    password: true
  },
  // Preferencias
  phone: {
    label: 'Teléfono',
    type: String,
    optional: true
  },
  _location: {
    label: 'Ubicación del mapa',
    type: String,
    location: true,
    fields: ['latitude', 'longitude']
  },
  latitude: {
    label: 'Latitud',
    type: Number,
    decimals: true,
    optional: true,
    visibility: false
  },
  longitude: {
    label: 'Longitud',
    type: Number,
    decimals: true,
    optional: true,
    visibility: false
  },
  zoom: {
    label: 'Zoom',
    type: Number,
    default: 5,
    optional: true
  },
  // Permisos
  disabled: {
    label: 'Deshabilitado',
    type: Boolean,
    optional: true
  },
  administrator: {
    label: 'Es administrador?',
    type: Boolean,
    optional: true
  },
  readonly: {
    label: 'Sólo lectura',
    type: Boolean,
    optional: true
  },
  deviceReadonly: {
    label: 'Unidad de sólo lectura',
    type: Boolean,
    optional: true
  },
  limitCommands: {
    label: 'Limitar comandos',
    type: Boolean,
    optional: true
  },
  expirationTime: {
    label: 'Vencimiento',
    type: Date,
    optional: true
  },
  deviceLimit: {
    label: 'Límite del unidades',
    type: Number,
    optional: true
  },
  userLimit: {
    label: 'Límite de usuario',
    type: Number,
    optional: true
  },
  _socialSeparator: {
    label: 'Twitter',
    type: String,
    separator: true
  },
  twitterGroup: {
    label: 'Grupo de interes',
    type: String,
    options: [{
      id: 'Operativo',
      name: 'Operativo'
    }, {
      id: 'Ejecutivo',
      name: 'Ejecutivo'
    }]
  },
  twitterGroupAdmin: {
    label: 'Puede cambiar keywords',
    type: Boolean
  },
  token: {
    label: 'Token',
    type: String,
    generated: true
  },
  ptz_control: {
    label: 'Control PTZ',
    type: Boolean,
    optional: true
  },
  alarm_sensor: {
    label: 'Alarmar Botones de Pánico',
    type: Boolean,
    optional: true
  },
  permission_groups: {
    label: 'Permiso a Grupos',
    type: Boolean,
    optional: true
  },
  permission_cameras: {
    label: 'Permiso a Camaras',
    type: Boolean,
    optional: true
  },
  permission_bus: {
    label: 'Permiso a Transporte',
    type: Boolean,
    optional: true
  },
  permission_geofences: {
    label: 'Permiso a Geocercas',
    type: Boolean,
    optional: true
  },
  permission_lpr: {
    label: 'Permiso a LPRs',
    type: Boolean,
    optional: true
  },
  permission_sensors: {
    label: 'Permiso a Botones de Pánico',
    type: Boolean,
    optional: true
  },
  permission_devices: {
    label: 'Permiso a Dispositivos GPS',
    type: Boolean,
    optional: true
  },
  permission_calendars: {
    label: 'Permiso a Calendarios',
    type: Boolean,
    optional: true
  },
  permission_scenarios: {
    label: 'Permiso a Escenarios',
    type: Boolean,
    optional: true
  },
  attributes: {
    label: 'Atributos',
    type: Object,
    optional: true,
    attributes: true,
  }
};
